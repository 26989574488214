import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import httpClient from './httpClient'

export class site extends BaseService {
  static get entity() {
    return 'site'
  }

  static async createSite(data) {
    try {
      const response = await httpClient.post(`${this.entity}/create`, data)
      return new ResponseWrapper(response, data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    } finally {
    }
  }

  static async updateSite(data) {
    try {
      const response = await httpClient.put(`${this.entity}/update`, data)
      return new ResponseWrapper(response, data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    } finally {
    }
  }

  static async updateSiteLocation(data) {
    try {
      const response = await httpClient.put(
        `${this.entity}/updatelocation`,
        data
      )
      return new ResponseWrapper(response, data)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    } finally {
    }
  }

  static async deleteSite(data) {
    try {
      const response = await httpClient.post(`${this.entity}/delete`, data)
      return new ResponseWrapper(response, {}, response.data.message)
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  static async checkUnique(data) {
    try {
      const response = await httpClient.post(`${this.entity}/checkUnique`, data)
      return response
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    } finally {
    }
  }

  static async getAllSites(data) {
    try {
      const response = await httpClient.get(`${this.entity}/getallsites`, data)
      return response
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    } finally {
    }
  }

  static async getAllSitesByRegionGroup(data) {
    try {
      const response = await httpClient.get(
        `${this.entity}/getallsitesbyregiongroupid/${data}`
      )

      return response
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getSiteBysiteID(data) {
    try {
      const response = await httpClient.get(
        `${this.entity}/findbysiteid/${data}`
      )
      return response
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getRemoteAccess(data) {
    try {
      const response = await httpClient.get(
        `${this.entity}/getremoteaccess/`,
        data
      )
      const token = response.data.loginToken
      var axios = require('axios')
      var qs = require('qs')
      var data = qs.stringify({
        data: token
      })
      var config = {
        method: 'post',
        url: 'https://dev-caleb.opstunnel.com/remoteconnection/api/tokens',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        // with credentials: true,
        credentials: true,
        data: data
      }

      axios(config)
        .then(function (response) {
          const token = response.data
          localStorage.setItem('GUAC_AUTH', JSON.stringify(token))
        })
        .catch(function (error) {})
      return response
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getContractorsWithSiteAccess(data) {
    try {
      const response = await httpClient.get(
        `${this.entity}/getcontractorswithsiteaccess/${data}`
      )
      return response
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
